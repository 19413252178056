import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterButton", "templateContainer"];

  connect() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadTemplates();
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(this.element);
    this.initializeFilter();
    this.setActiveFilter("pdf");
  }

  initializeFilter() {
    this.filterButtonTargets.forEach(button => {
      button.addEventListener("click", () => {
        const filterValue = button.getAttribute("data-initial-value").toLowerCase();
        this.setActiveFilter(filterValue);
      });
    });
  }

  setActiveFilter(filterValue) {
    this.activeFilter = filterValue;
    this.filterButtonTargets.forEach(button => {
      button.classList.toggle("active", button.getAttribute("data-initial-value").toLowerCase() === filterValue);
    });
    this.loadTemplates();
  }

  loadTemplates() {
    const templatesData = JSON.parse(this.templateContainerTarget.dataset.templates);
    const filteredTemplates = templatesData.filter(template => template.title.toLowerCase() === this.activeFilter)
      .flatMap(template => template.pagesSortedByPositionMeQr.map(page => ({
        id: page.id,
        image: page.image
      })));
    this.generateCarousel(filteredTemplates);
  }

  generateCarousel(templates) {
    const chunkSize = this.getChunkSize();
    const templateChunks = [];
    for (let i = 0; i < templates.length; i += chunkSize) {
      templateChunks.push(templates.slice(i, i + chunkSize));
    }

    const slidesHTML = templateChunks.map((chunk, index) => {
      const templatesHTML = chunk.map(page => `
        <div class="col-lg-2 col-md-3 col-6 text-center">
          <a href="/entry/constructor/clone/${page.id}" class="d-block template-main-item-img overflow-hidden">
            <img src="${page.image}" alt="Template Image ${page.id}" class="img-fluid">
          </a>
        </div>
      `).join("");

      return `
        <div class="carousel-item ${index === 0 ? 'active' : ''}">
          <div class="row gx-3 justify-content-xl-center justify-content-between mt-4 mb-4">
            ${templatesHTML}
          </div>
        </div>
      `;
    }).join('');

    const indicatorsHTML = templateChunks.length > 1 ? templateChunks.map((_, index) => `
      <button type="button" data-bs-target="#carouselExampleCaptions" 
        data-bs-slide-to="${index}" 
        class="${index === 0 ? 'active' : ''}" 
        aria-current="${index === 0 ? 'true' : 'false'}" 
        aria-label="Slide ${index + 1}">
      </button>
    `).join('') : '';

    this.templateContainerTarget.innerHTML = `
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        ${indicatorsHTML ? `<div class="carousel-indicators">${indicatorsHTML}</div>` : ''}
        <div class="carousel-inner">
          ${slidesHTML}
        </div>
      </div>
    `;

    const carouselElement = document.querySelector("#carouselExampleCaptions");
    if (carouselElement) {
      new bootstrap.Carousel(carouselElement, {
        interval: 3000,
        ride: "carousel"
      });
    }
  }

  getChunkSize() {
    if (window.innerWidth < 768) {
      return 2;
    } else if (window.innerWidth < 992) {
      return 4;
    } else {
      return 6;
    }
  }
}
