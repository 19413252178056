import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import debounce from "debounce";
import { Modal } from "bootstrap";
export default class extends Controller {
  static values = {
    userId: Number,
    userToken: String,
    isShowSegmentModal: Boolean,
  };

  static targets = [
    "periodOption",
    "entry_search_query",
    "filter",
    "archived",
    "entries",
    "csrf_token",
    "qrcodeCheckbox",
    "filterActionBtn",
    "selectFolder",
    "selectFolderErr",
    "formatFolder",
    "formatFolderSize",
    "downloadFolderBtn",
  ];

  copyModal = new Modal("#createCopyBulkModal");
  deleteQrModal = new Modal("#deleteQrBulk");
  moveToFolderModal = new Modal("#moveToFolderBulkModal");
  moveFromPremiumToRegularFolderModal = new Modal("#moveFromPremiumToRegularFolderBulkModal");
  storageIsFullModal = new Modal("#storage-size-exceeded");
  preloader = new Modal(".loaderWrapper");
  downloadFolderModal = new Modal("#downloadFolderModal");

  connect() {
    this.fetchSearchQrs = this.fetchSearch.bind(this);
    this.debouncedCountWords = debounce(() => {
      if (
        this.entry_search_queryTarget.value.length >= 3 ||
        this.entry_search_queryTarget.value.length === 0
      ) {
        this.fetchSearchQrs();
      }
    }, 500);
    this.entry_search_queryTarget.addEventListener(
      "keyup",
      this.debouncedCountWords
    );
    const urlParams = new URLSearchParams(window.location.search);
    const dateFilter = urlParams.get("dateFilter");
    if (dateFilter) {
      this.periodOptionTarget
        .querySelector(`option[value="${dateFilter}"]`)
        ?.setAttribute("selected", true);
    }

    if (this.isShowSegmentModalValue) {
      this.showSegmentModal();
    }
  }

  disconnect() {
    this.entry_search_queryTarget.removeEventListener(
      "keyup",
      this.debouncedCountWords
    );
  }

  selectType(e) {
    let locale = this.getCookie("user_local");
    if (locale === undefined) {
      locale = "en";
    }
    const currentUrl = new URL(window.location.href);
    const isArchieved = currentUrl.searchParams.get("archived");

    if (locale === undefined) {
      locale = "en";
    }

    window.location.href = `${locale !== "en" ? `/${locale}` : ""}/entry/${
      e.target.value
    }${isArchieved ? "?archived=1" : ""}`;
  }

  selectPeriod(e) {
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("dateFilter", e.target.value);
    window.location.href = currentUrl.toString();
  }
  eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }

  selectLimit(e) {
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("limit", e.currentTarget.dataset.value);
    window.location.href = currentUrl.toString();
  }

  async search(e) {
    e.preventDefault();
    this.fetchSearchQrs();
  }

  async fetchSearch() {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.csrf_tokenTarget.value;

    const { data } = await axios(
      `?entry_search_query=${this.entry_search_queryTarget.value}&filter=${this.filterTarget.value}&archived=${this.archivedTarget.value}`,
      { responseType: "text" }
    );
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(data, "text/html");
    const entriesDiv = htmlDoc.querySelector("#entries");
    this.entriesTarget.innerHTML = entriesDiv.innerHTML;
  }

  emailVerify(e) {
    axios(e.currentTarget.dataset.href);
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [key, value] = el.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }

  createNewBtn() {
    dataLayer.push({ event: "button_Create_New_QR_Code" });
  }

  selectAllQrs(e) {
    let target = e.target;
    let li = target.closest("li");

    if (li.classList.contains("disable")) {
      e.target.checked = false;
      return false;
    }

    if (e.target.classList.contains("disable")) {
      e.target.checked = false;
      return false;
    }
    this.filterActionBtnTargets.forEach((item) => {
      if (e.target.checked) {
        item.classList.remove("disable");
      } else {
        item.classList.add("disable");
      }
    });
    this.qrcodeCheckboxTargets.forEach((item) => {
      const selectable = item.closest(".selectable");
      if (e.target.checked) {
        item.checked = true;
        item.setAttribute("checked", "");
        selectable.classList.add("bg-primary-light", "border-primary");
      } else {
        item.checked = false;
        item.removeAttribute("checked");
        selectable.classList.remove("bg-primary-light", "border-primary");
      }
    });
  }

  checkQr(e) {
    const selectable = e.currentTarget.closest(".selectable");
    selectable.classList.toggle("bg-primary-light");
    selectable.classList.toggle("border-primary");
    let userId = document.querySelector("[data-user-id]").getAttribute("data-user-id");
    let qrUserIds = document.querySelectorAll("[data-entry-user-id] ");
    let moveFolder = document.querySelector("[data-action=\"click->admin--qr-filters#showFolerQrModal\"]");
    let changeOwner = document.querySelector("[data-action=\"click->admin--qr-filters#changeOwner\"]");

    let needDisableChangeOwner = false;

    if (this.qrcodeCheckboxTargets.some((item) => item.checked)) {
      this.filterActionBtnTargets.forEach((item) =>
        item.classList.remove("disable")
      );

      qrUserIds.forEach((item) => {
        let input = item.querySelector(".form-check-input");
        if (input.checked) {
          if (userId !== item.getAttribute("data-entry-user-id")) {
            needDisableChangeOwner = true;
          }
        }
      });

      if (needDisableChangeOwner) {
        [changeOwner, moveFolder].forEach((element) => {
          if (element.classList.contains("disable") === false) {
            element.classList.add("disable");
          }
        });
      } else {
        [changeOwner, moveFolder].forEach((element) => {
          if (element.classList.contains("disable") === true) {
            element.classList.remove("disable");
          }
        });
      }

    } else {
      this.filterActionBtnTargets.forEach((item) =>
        item.classList.add("disable")
      );
    }
  }

  showCopyModal(e) {
    e.preventDefault();
    let element = e.target.closest(".head-menu");
    if (!element.classList.contains("disable")) {
      this.copyModal.show();
    }
  }

  showDeleteQrModal(e) {
    e.preventDefault();
    let element = e.target.closest(".head-menu");
    if (!element.classList.contains("disable")) {
      this.deleteQrModal.show();
    }
  }

  showFolerQrModal(e) {
    e.preventDefault();
    let element = e.target.closest(".head-menu");
    let ids = [];
    this.qrcodeCheckboxTargets.forEach((item) => {
      if (item.checked) {
        ids.push(item.dataset.id);
      }
    });
    let button = document.querySelector("#qrId");
    button.dataset.qr = JSON.stringify(ids);
    if (!element.classList.contains("disable")) {
      this.moveToFolderModal.show();
    }
  }

  async copyQrBulk() {
    this.copyModal.hide();
    this.preloader._element.classList.remove("d-none");
    const formData = new FormData();
    this.qrcodeCheckboxTargets.forEach((item) => {
      if (item.checked) {
        formData.append("entries[]", item.dataset.id);
      }
    });
    let locale = document.querySelector('[data-locale]').getAttribute('data-locale')
    formData.append('locale', locale)
    try {
      let { data } = await axios.post("/entry/bulk/copy", formData);
      this.preloader._element.classList.add("d-none");
      if (data.status === "error") {
        if (data.error === "Not enough space") {
          this.storageIsFullModal.show();
          return false;
        } else if (data.error === "Authorization") {
          window.location.href = "/login";
          return false;
        }else{
          const alertError = document.querySelector("#entryAlertError");
          alertError.classList.remove("d-none");
          alertError.querySelector("div").innerText = data?.error;
          setTimeout(() => {alertError.classList.add("d-none")}, 5000);
          return false;
        }
      }
      window.location.reload();
    } catch (e) {}
  }

  async deleteQrBulk(e) {
    e.preventDefault();
    this.deleteQrModal.hide();
    this.preloader._element.classList.remove("d-none");
    let pagination = document.getElementsByClassName("pagination");
    let totalPages = 1;
    if (pagination.length > 0) {
      totalPages = pagination[0].childElementCount;
    }
    let numberOItems = this.qrcodeCheckboxTargets.length;
    let numberOfSelectedItems = 0;

    const formData = new FormData();

    this.qrcodeCheckboxTargets.forEach((item) => {
      if (item.checked) {
        formData.append("entries[]", item.dataset.id);
        numberOfSelectedItems++;
      }
    });
    try {
      if (totalPages === 1 && numberOItems === numberOfSelectedItems) {
        this.eraseCookie("filterByType");
      }
      const { data } = await axios.post("/entry/bulk/delete", formData);
      this.preloader._element.classList.add("d-none");
      if (data?.status === "error") {
        const alertError = document.querySelector("#entryAlertError");
        alertError.classList.remove("d-none");
        alertError.querySelector("div").innerText = data?.error;
        setTimeout(() => alertError.classList.add("d-none"), 5000);
        return;
      }
      window.location.reload();
    } catch (e) {}
  }

  async moveToFolderBulk(e) {

    let target = e.target;

    if (target.classList.contains("premiumToRegularFolderConfirmBulk") === false) {
      let options = this.selectFolderTarget.options;
      let selectedIndex = this.selectFolderTarget.selectedIndex;
      let premiumFolderTo = options[selectedIndex].getAttribute("data-premium-folder");
      let premiumFolderFrom = '0';
      let selectedFolderName = ''

      this.qrcodeCheckboxTargets.forEach((item) => {
        if (item.checked) {
          let dataPremiumFolder = item.closest('.col-12').querySelector('[data-premium-folder]');
          if(dataPremiumFolder){
            let isPremium = dataPremiumFolder.getAttribute('data-premium-folder');
            if(isPremium === '1'){
              premiumFolderFrom = isPremium
              selectedFolderName = dataPremiumFolder.innerHTML
            }
          }
        }
      });

      if (premiumFolderFrom === "1" && premiumFolderTo === "") {
        let locale = this.getCookie("user_local");
        if (locale === undefined) {
          locale = "en";
        }

        this.moveToFolderModal.hide();
        this.moveFromPremiumToRegularFolderModal.show();

        let titleElement = document.querySelector(".title_from_premium_to_regular_folder_bulk");
        let title = titleElement.innerHTML;
        let titleSplip = title.split("/");

        let newTitle = titleSplip[0] + selectedFolderName + titleSplip[1];
        if (["ar", "he"].includes(locale)) {
          newTitle = titleSplip[1] + selectedFolderName + titleSplip[0];
        }
        titleElement.innerHTML = newTitle;

        return false;
      } else {
        this.moveFromPremiumToRegularFolderModal.hide();
      }
    }

    this.moveToFolderModal.hide();
    this.preloader._element.classList.remove("d-none");
    if (this.selectFolderTarget.value === "select") {
      this.selectFolderErrTarget.classList.remove("d-none");
      setTimeout(
        () => this.selectFolderErrTarget.classList.add("d-none"),
        5000
      );
      return;
    }
    const formData = new FormData();
    this.qrcodeCheckboxTargets.forEach((item) => {
      if (item.checked) {
        let dataPremiumFolder = item.closest('.col-12').querySelector('[data-premium-folder]');
        if(dataPremiumFolder){
          let isPremium = dataPremiumFolder.getAttribute('data-premium-folder');
          if(isPremium === '1'){

          }
        }
        formData.append("entries[]", item.dataset.id);
      }
    });

    formData.append("folderId", this.selectFolderTarget.value);
    try {
      await axios.post("/entry/bulk/folder", formData);
      this.preloader._element.classList.add("d-none");
      window.location.reload();
    } catch (e) {}
  }

  buyPremium() {
    window.location.href = "/subscription/admin";
  }

  cancel() {
    window.location.reload();
  }

  changeOwner(e) {
    e.preventDefault();
    let target = e.target;
    let li = target.closest("li");
    let a = li.querySelector('a');

    if (a.classList.contains("disable")) {
      return false;
    }

    const qrIds = [];
    this.qrcodeCheckboxTargets.forEach((item) => {
      if (item.checked) {
        qrIds.push(item.dataset.id);
      }
    });
    let locale = this.getCookie("user_local");
    if (locale === undefined) {
      locale = "en";
    }
    window.location.href = `${
      locale !== "en" ? `/${locale}` : ""
    }/entry/entries_list?entries=${qrIds.join(",")}`;
  }

  async acceptChangeOwner(e) {
    const formData = new FormData();
    formData.append("email", e.currentTarget.dataset.email);
    try {
      await axios.post("/entry/change_owner_request/accept", formData);
      window.location.reload();
    } catch (e) {}
  }

  async declineChangeOwner(e) {
    const formData = new FormData();
    formData.append("email", e.currentTarget.dataset.email);
    try {
      await axios.post("/entry/change_owner_request/declin", formData);
      window.location.reload();
    } catch (e) {}
  }

  deleteFolderModalInit(e) {
    document
      .querySelectorAll(".deleteFolderName")
      .forEach((item) => (item.innerHTML = e.target.dataset.folderName));
    document.querySelector("#deleteFolderBtn").dataset.folderId =
      e.target.dataset.folderId;
  }

  getFolderName(e) {
    const oldFolderName = document.querySelector("#oldFolderName");
    oldFolderName.innerHTML = e.currentTarget.dataset.folder;
    oldFolderName.dataset.folderId = e.currentTarget.dataset.folderId;
  }

  downloadFolderInit(e) {
    let folderReportId = e.target.dataset.folderReportId
    this.downloadFolderBtnTarget.dataset.folderId = e.target.dataset.folderId;
    this.downloadFolderBtnTarget.dataset.folder = e.target.dataset.folder;
    this.downloadFolderBtnTarget.dataset.folderReportId = folderReportId;

    folderReportId = +folderReportId;

    if (folderReportId === 0) {
      const downloadFolderBtn = document.querySelector("#deleteFolderReportBtn");
      if (downloadFolderBtn) {
        downloadFolderBtn.classList.add("d-none");
      }
    }
  }

  async downloadFolder(e) {
    const downloadBtn = e.currentTarget;
    downloadBtn.disabled = true;
    const loadingBoxElement = document.querySelector('.js-loading-box');
    loadingBoxElement.classList.remove('d-none');
    const formatSelectorElement = document.querySelector('.js-format-selector');
    // formatSelectorElement.classList.add('d-none');
    let extension = document.querySelector('#formatFolder').value;
    let size = document.querySelector('#formatFolderSize').value;

    const folderName = `${e.currentTarget.dataset.folder}.zip`;
    const formData = new FormData();
    formData.append("folderId", e.currentTarget.dataset.folderId);
    formData.append("extension", extension);
    formData.append("size", size);
    try {
      let errors = document.querySelectorAll('.flash-error');
      let errorsArray = [...errors]; // converts NodeList to Array
      errorsArray.forEach(div => {
        if(!div.classList.contains('d-none')){
          div.classList.add('d-none')
        }
      });

      const { data } = await axios.post("/entry/folder/download/qr", formData, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", folderName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (e) {
      if (e.response.status === 403) {
        document.querySelector("#flash-error-403").classList.remove("d-none");
      } else if (e.response.status === 429) {
        document.querySelector("#flash-error-429").classList.remove("d-none");
      } else if (e.response.status === 404) {
        document.querySelector("#flash-error-404").classList.remove("d-none");
      } else if (e.response.status === 430) {
        try {
          const { data } = await axios.post("/entry/folder/download/qr/link", formData);
          if (data.status === "success") {
            window.location.href = data.link;
          } else {
            document.querySelector("#flash-error-403").classList.remove("d-none");
          }
        } catch (e1) {
          document.querySelector("#flash-error-403").classList.remove("d-none");
        }
      }
      else if (e.response.status === 400) {
        document.querySelector("#flash-error-400").classList.remove("d-none");
      }
      else {
        document.querySelector("#flash-error-500").classList.remove("d-none");
      }

      downloadBtn.disabled = false;
      loadingBoxElement.classList.add('d-none');
      formatSelectorElement.classList.remove('d-none');

      return false;
    }

    downloadBtn.disabled = false;
    loadingBoxElement.classList.add('d-none');
    // formatSelectorElement.classList.remove('d-none');
    this.downloadFolderModal.hide();
  }

  async deleteFolderReportBtn(e) {
    const deleteBtn = e.currentTarget;
    const modalContent = deleteBtn.closest(".modal-content");
    const buttonWithReportId = modalContent.querySelector("button[data-folder-report-id]");

    if (buttonWithReportId) {
      const folderId = buttonWithReportId.getAttribute("data-folder-id");
      if (folderId) {
        try {
          const { data } = await axios.get(`/entry/folder/delete/qr/${folderId}/report`);
          if (data.status === "success") {
            deleteBtn.classList.add("d-none");
          } else {
            console.log("inside2");
            document.querySelector("#flash-error-403").classList.remove("d-none");
          }
        } catch (e1) {
          console.log("inside1");
          document.querySelector("#flash-error-403").classList.remove("d-none");
        }
      }
    }
  }

  showSegmentModal()
  {
    const userId = this.userIdValue;
    const userToken = this.userTokenValue;
    if (userId === 0 || userToken === '') {
      console.error('User params is undefined');
      return;
    }
    const segmentModalElem = document.getElementById('chooseCat');
    if (!segmentModalElem) {
      return;
    }
    const segmentSelectBox = segmentModalElem.querySelector('.js-segment-select-box');
    const segmentSuccessBox = segmentModalElem.querySelector('.js-segment-success-box');
    if (!segmentSelectBox || !segmentSuccessBox) {
      console.error('Segment content boxes is undefined');
      return;
    }
    const saveBtn = document.querySelector('.js-send-segment-btn');
    if (!saveBtn) {
      console.error('Save segment btn is undefined');
      return;
    }
    const segmentModal = new Modal("#chooseCat");
    segmentModal.show();

    saveBtn.addEventListener('click', function () {
      saveBtn.disabled = true;

      const segmentRadioInput = document.querySelector(`input[name="segmentRadioInput"]:checked`);
      if (!segmentRadioInput) {
        console.error('Checked segment value is undefined');
        return;
      }

      saveSegmentByUser(segmentRadioInput.value);
    });

    segmentModalElem.addEventListener('hidden.bs.modal', closeSegmentModal);
    function closeSegmentModal() {
      saveSegmentByUser('dontKnowSegment');
    }

    function saveSegmentByUser(segmentSlug) {
      try {
        axios.post("/ajax/user/choose-segment", {
          'userId': userId,
          'userToken': userToken,
          'segmentSlug': segmentSlug,
        })
            .then(function (response) {
              segmentModalElem.removeEventListener('hidden.bs.modal', closeSegmentModal);
              segmentSelectBox.classList.add('d-none');
              segmentSuccessBox.classList.remove('d-none');
            })
            .catch(function () {
              saveBtn.disabled = false;
            })
        ;
      } catch (e) {}
    }
  }

  tryProBtn() {
    gtag("event", "button_try_pro_vesion");
  }
}
