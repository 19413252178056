import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { Modal } from "bootstrap";
import debounce from "debounce";
import { webView, webviewEvents } from "../../../variables/webView";
import paymentMiddlewareUrlService from "../../service/payment/payment_middleware_url_service";

const sizeOptions = [
  "100 MB",
  "200 MB",
  "300 MB",
  "400 MB",
  "500 MB",
  "600 MB",
  "700 MB",
  "800 MB",
  "900 MB",
  "1 GB",
  "2 GB",
  "4 GB",
  "6 GB",
  "8 GB",
  "10 GB",
  "20 GB",
  "40 GB",
  "60 GB",
  "80 GB",
  "100 GB",
  "200 GB",
  "400 GB",
  "600 GB",
  "800 GB",
  "1 TB",
];

const quantity = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 40, 60, 80, 100, 200, 400, 600, 800, 1000, 2000, 4000, 6000, 8000, 10000,
];

export default class extends Controller {
  static values = {
    coupon: String,
    isShowMultiSub: Boolean,
    typeText: String,
    notFound: String,
  };

  static targets = [
    "premiumYear",
    "liteYear",
    "premium",
    "lite",
    "currencySelect",
    "chooseModal",
    "wrongPromo",
    "noActivePromo",
    "clickableRow",
    "errorManage",
    "liteQrSelectModal",
    "changeQrErr",
    "annually",
    "monthly",
    "annuallyLabel",
    "monthlyLabel",
    "promoForm",
    "storageMB",
    "storagePrice",
    "storageRange",
    "saveLite",
    "savePremium",
    "storageWarning",
    "storageDanger",
    "cancelSubscription",
    "promoField",
    "editPromoForm",
    "storageMonthYear",
    "switchYearMonth",
    "storageType",
    "storageChoice",
    "extendStorageBtn",
    "changeQrLiteBtn",
    "buyLiteBtn",
    "selectPlan",
    "selectPeriod",
    "selectPlanPrice",
    "selectStoragePeriod",
    "subscribeMultisubBtn",
    "selectPlanPriceWithoutCoupon",
    "modalSearch",
    "storageThumb",
    "changeSizeBtnModal",
    "userStorage",
    "subscribedStorage",
    "tooltipSummaryFileSize",
    "tooltipAllowedFileSize",
    "cancelSubscriptionStorage",
    "storageSubscribeWeeksUntil",
  ];

  symbol = {
    aud: "A$",
    brl: "R$",
    cad: "C$",
    eur: "€",
    gbp: "£",
    idr: "Rp",
    inr: "₹",
    sar: "SR",
    thb: "฿",
    uah: "₴",
    usd: "$",
    vnd: "₫",
  };

  plansData = {};
  currentCurrency = "usd";
  coupon = null;

  selectLiteSubscriptionType = null;
  selectQuantityOrder = null;

  qrOrderIdLiteModal = null;
  qrIdLiteModal = null;
  storagePrice = 0;
  selectedQuantityUnits = quantity[0];

  QRCodeStyling = null;
  isModalDataLoading = false;
  currentStepModalData = 1;
  totalStepCountModalData = 1;
  isInitModalAsSubscribeMode = true;

  liteQrSelectModal = new Modal(this.liteQrSelectModalTarget);
  cancelSubscriptionModal = new Modal(document.getElementById("cancelSubscription"));
  cancelSubscriptionStorageModal = new Modal(document.getElementById("cancelSubscriptionStorage"));

  async connect() {
    this.checkModalScroll();

    if (this.couponValue !== '') {
      this.coupon = this.couponValue;
      this.showPromo();
      await this.showActivePromo();
    }

    this.currencySelectTarget.value = "usd";
    await this.loadPricingForAllPlans();
    await this.changeMultiSubPlan();

    const liteQrSelectModal = document.getElementById("lite-qr-select");
    liteQrSelectModal.addEventListener("hidden.bs.modal", () => {
      this.resetLiteQrSelectModal();
    });
    const usedStoragePercents =
      (this.storageRangeTarget.value * 100) / this.storageRangeTarget.max;
    if (usedStoragePercents >= 90) {
      this.storageDangerTarget.classList.remove("d-none");
      this.storageRangeTarget.style.setProperty("--primary-color", `#F44336`);
    } else if (usedStoragePercents >= 80) {
      this.storageWarningTarget.classList.remove("d-none");
      this.storageRangeTarget.style.setProperty("--primary-color", `#E1B02F`);
    }
    this.resetPlanSwitch();

    this.QRCodeStyling = (await import("qr-code-styler")).default;
    this.searchQr = await debounce(this.searchQr.bind(this), 1000);
  }

  async loadPricingForAllPlans()
  {
    const currency = this.currentCurrency;
    const requestData = {};
    if (this.coupon) {
      requestData['coupon'] = this.coupon;
    }

    await axios.post("/get-all-plans-with-storage", requestData).then(({ data }) => {
      this.plansData = data;
      this.resetPricingByCurrency(currency);
      this.changeSizeBtnModalTarget.classList.remove("disabled");
      this.resetStorageSlider();
    });
  }

  resetPlanSwitch() {
    this.annuallyLabelTarget.classList.add("fw-bold");
    this.annuallyLabelTarget.classList.add("text-gray");
    this.annuallyLabelTarget.classList.add("text-dark");
    this.monthlyLabelTarget.classList.remove("fw-bold");
    this.monthlyLabelTarget.classList.remove("text-gray");
    this.monthlyLabelTarget.classList.remove("text-dark");
    this.switchYearMonthTarget.checked = false;
    this.switchYearMonthTarget.removeAttribute("checked");
  }

  resetPricingByCurrency(currency) {
    this.setPriceByTargets(this.liteYearTargets, this.plansData["lite_year"], currency);
    this.setPriceByTargets(this.premiumYearTargets, this.plansData["premium_year"], currency);
    this.setPriceByTargets(this.liteTargets, this.plansData["lite_month"], currency);
    this.setPriceByTargets(this.premiumTargets, this.plansData["premium_month"], currency);
    this.calculateSavePrice(currency);
  }

  setPriceByTargets(targets, planCurrencyPricing, currency) {
    targets.forEach((target) => {
      const price = planCurrencyPricing.find((currencyPrice) => currencyPrice.currency === currency).amount;

      target.innerHTML = `${this.symbol[currency]}${price}`
    });
  }

  calculateSavePrice(currency) {
    const liteYearData = this.plansData["lite_year"].find((item) => item.currency === currency);
    const liteMonthData = this.plansData["lite_month"].find((item) => item.currency === currency);
    const premiumYearData = this.plansData["premium_year"].find((item) => item.currency === currency);
    const premiumMonthData = this.plansData["premium_month"].find((item) => item.currency === currency);

    const liteAmountYear = liteYearData['amount'];
    const liteMonthPricePerUnit = liteMonthData['price_per_unit'];
    const liteMonthPriceFor12Month = liteMonthPricePerUnit * 12;
    const liteYearProfit = this.roundPrice(liteMonthPriceFor12Month - liteAmountYear);

    const premiumAmountYear = premiumYearData['amount'];
    const premiumMonthPricePerUnit = premiumMonthData['price_per_unit'];
    const premiumMonthPriceFor12Month = premiumMonthPricePerUnit * 12;
    const premiumYearProfit = this.roundPrice(premiumMonthPriceFor12Month - premiumAmountYear);

    this.saveLiteTargets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${liteYearProfit}`;
    });

    this.savePremiumTargets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${premiumYearProfit}`;
    });
  }

  roundPrice(price)
  {
    price = Number(price.toFixed(2));
    if (price % 1 === 0) {
      return Math.round(price);
    }

    return price;
  }

  async selectCurrency(e) {
    this.currentCurrency =  e.target.value;
    this.resetPricingByCurrency(e.target.value);
    this.resetStorageSlider();
    await this.changeMultiSubPlan();
  }

  showPromo() {
    this.promoFormTarget.classList.toggle("d-none");
  }

  async checkPromo() {
    const coupon = this.promoFieldTarget.value;
    if (coupon === '') {
      console.error('Coupon is empty');
      return;
    }
    this.coupon = coupon;

    try {
      const { data } = await axios.post(
      "/subscription/check-promocode",
          {
            "coupon": coupon,
          }
      );

      const isActive = data.data?.isActive;
      if (isActive) {
        this.showActivePromo();
        await this.loadPricingForAllPlans();
        await this.changeMultiSubPlan();
      } else if (!isActive) {
        this.showNoActivePromo();
      }
    } catch (err) {
      this.showErrorPromo();
    }
  }

  async editPromo() {
    this.coupon = null;
    this.editPromoFormTarget.classList.add("d-none");
    this.editPromoFormTarget.querySelector("#promoValue").innerText = "";
    this.promoFormTarget.classList.remove("d-none");
    await this.loadPricingForAllPlans();
    await this.changeMultiSubPlan();
  }

  showActivePromo() {
    this.editPromoFormTarget.querySelector("#promoValue").innerText = this.coupon;
    this.promoFormTarget.classList.add("d-none");
    this.editPromoFormTarget.classList.remove("d-none");
  }

  showNoActivePromo() {
    this.noActivePromoTarget.classList.remove("d-none");
    setTimeout(() => {
      this.noActivePromoTarget.classList.add("d-none");
    }, 3000);
  }

  showErrorPromo() {
    this.wrongPromoTarget.classList.remove("d-none");
    setTimeout(() => {
      this.wrongPromoTarget.classList.add("d-none");
    }, 3000);
  }

  checkoutPremiumYear() {
    this.sendMessageToMobileApp('app_subscription_premium');

    window.location.href = paymentMiddlewareUrlService.getPremiumYearMiddlewareUrl({
      currency: this.currentCurrency,
      coupon: this.coupon,
    });
  }

  checkoutPremiumMonth() {
    this.sendMessageToMobileApp('app_subscription_premium');

    window.location.href = paymentMiddlewareUrlService.getPremiumMonthMiddlewareUrl({
      currency: this.currentCurrency,
      coupon: this.coupon,
    });
  }

  checkoutLiteYearWithoutQr() {
    this.sendMessageToMobileApp('app_subscription_lite');

    window.location.href = paymentMiddlewareUrlService.getLiteYearMiddlewareUrl({
      currency: this.currentCurrency,
      coupon: this.coupon,
    });
  }

  checkoutLiteMonthWithoutQr() {
    this.sendMessageToMobileApp('app_subscription_lite');

    window.location.href = paymentMiddlewareUrlService.getLiteMonthMiddlewareUrl({
      currency: this.currentCurrency,
      coupon: this.coupon,
    });
  }

  checkoutLite() {
    if (
        !this.selectLiteSubscriptionType ||
        !paymentMiddlewareUrlService.isLiteSubscriptionType(this.selectLiteSubscriptionType)
    ) {
      console.error('Lite subscription type is undefined');
      return;
    }
    let quantityOrder = null;
    if (this.isShowMultiSubValue) {
      quantityOrder = this.selectQuantityOrder;
    }

    window.location.href = paymentMiddlewareUrlService.getLiteMiddlewareUrl({
      subscriptionType: this.selectLiteSubscriptionType,
      entryId: this.qrIdLiteModal,
      currency: this.currentCurrency,
      quantityOrder: quantityOrder,
      coupon: this.coupon,
    });
  }

  async checkoutMultiSub(e) {
    const selectedOption = this.selectPeriodTarget.options[this.selectPeriodTarget.selectedIndex];
    const selectPlanTitle = this.selectPlanTarget.value;
    const selectPlanPeriod = selectedOption.dataset.type;
    const quantityOrder = selectedOption.dataset.quantityOrder;

    if (this.selectPlanTarget.value === "lite") {
      if (!e.target.dataset.isUserHasEntries) {
        window.location.href = paymentMiddlewareUrlService.getLiteMiddlewareUrl({
          subscriptionType: `${selectPlanTitle}_${selectPlanPeriod}`,
          currency: this.currentCurrency,
          quantityOrder: quantityOrder,
          coupon: this.coupon,
        });
        return;
      }

      this.liteQrSelectModal.show();
      this.isInitModalAsSubscribeMode = true;
      let loaded = this.liteQrSelectModalTarget.dataset.loaded;
      if (loaded !== '1') {
        await this.loadQrSelectModalContent({ qrId: null, isChangeQrAction: false });
      } else {
        this.buyLiteBtnTarget.classList.remove("d-none");
      }
    } else {
      window.location.href = paymentMiddlewareUrlService.getPremiumMiddlewareUrl({
        subscriptionType: `${selectPlanTitle}_${selectPlanPeriod}`,
        currency: this.currentCurrency,
        quantityOrder: quantityOrder,
        coupon: this.coupon,
      });
    }
  }

  async changeMultiSubPlan() {
    if (!this.isShowMultiSubValue) {
      return;
    }
    this.subscribeMultisubBtnTarget.classList.add("disabled");

    const selectedOption = this.selectPeriodTarget.options[this.selectPeriodTarget.selectedIndex];
    const selectPlanTitle = this.selectPlanTarget.value;
    const selectPlanPeriod = selectedOption.dataset.type;
    const subscriptionType = `${selectPlanTitle}_${selectPlanPeriod}`;
    const quantityOrder = selectedOption.dataset.quantityOrder;

    if (!paymentMiddlewareUrlService.isValidSubscriptionType(subscriptionType)) {
      console.error('Subscription type is invalid');
      return;
    }
    if (paymentMiddlewareUrlService.isLiteSubscriptionType(subscriptionType)) {
      this.selectLiteSubscriptionType = subscriptionType;
      this.selectQuantityOrder = quantityOrder;
    }

    const requestData = {
      type_key: subscriptionType,
      currency: this.currentCurrency,
      quantity_order: quantityOrder,
    };
    if (this.coupon) {
      requestData.coupon = this.coupon;
    }

    try {
      const { data } = await axios.post("/api/stripe/get-subscription-amount", requestData);
      const symbol = this.symbol[this.currentCurrency];
      const amount = data.data['amount'];
      const pricePerUnit = data.data['price_per_unit'];

      this.selectPlanPriceTarget.innerHTML = `${symbol}${amount}`;
      this.selectPlanPriceWithoutCouponTarget.innerHTML = `${symbol}${pricePerUnit}`;
    } catch (err) {
    } finally {
      this.subscribeMultisubBtnTarget.classList.remove("disabled");
    }
  }

  multiSubscribeNavigate() {
    document.cookie = "multiSub=1";
    window.location.reload();
  }

  regularSubscribeNavigate() {
    document.cookie = "multiSub=0";
    window.location.reload();
  }

  async cancelSubscription(e) {
    const dataset = e.target.dataset;
    this.errorManageTarget.classList.add("d-none");
    e.target.classList.add("disabled");
    try {
      const { data } = await axios.post(dataset.url, {
        order_id: dataset.order,
        user_token: dataset.userToken,
        order_type: dataset.plan,
      });
      if (!!data.ok) {
        window.location.reload();
      } else {
        this.errorManageTarget.classList.remove("d-none");
      }
    } catch (err) {
      this.errorManageTarget.classList.remove("d-none");
    } finally {
      e.target.classList.remove("disabled");
    }
    dataset.plan === "storage"
      ? this.cancelSubscriptionStorageModal.hide()
      : this.cancelSubscriptionModal.hide();
  }

  async showLiteModal(e) {
    this.sendMessageToMobileApp('app_subscription_lite');

    const liteSubscriptionType = e.target.dataset.subscriptionType ?? null;
    if (liteSubscriptionType === paymentMiddlewareUrlService.liteYearType) {
      this.selectLiteSubscriptionType = paymentMiddlewareUrlService.liteYearType;
    } else if (liteSubscriptionType === paymentMiddlewareUrlService.liteMonthType) {
      this.selectLiteSubscriptionType = paymentMiddlewareUrlService.liteMonthType;
    }

    this.isInitModalAsSubscribeMode = true;
    let loaded = this.liteQrSelectModalTarget.dataset.loaded;
    if (loaded !== '1') {
      await this.loadQrSelectModalContent({ qrId: null, isChangeQrAction: false });
    } else {
      this.buyLiteBtnTarget.classList.remove("d-none");
    }
  }

  async liteQrSelect(e) {
    this.isInitModalAsSubscribeMode = false;
    let loaded = this.liteQrSelectModalTarget.dataset.loaded;
    const qrId = e.currentTarget.dataset.qrId;
    this.qrOrderIdLiteModal = e.currentTarget.dataset.qrOrderId;
    this.qrIdLiteModal = qrId;
    if (loaded !== '1') {
      await this.loadQrSelectModalContent({ qrId, isChangeQrAction: true });
    } else {
      this.changeQrLiteBtnTarget.classList.remove("d-none");
      const qrItems = document.querySelectorAll(".qr-item");
      qrItems.forEach((item) => {
        if (qrId === item.dataset.qrId) {
          const inputElement = item.querySelector("input");
          inputElement.checked = true;
          inputElement.setAttribute("checked", "");
          item.classList.add("border-primary");
          this.qrIdLiteModal = item.dataset.qrId;
        }
      });
    }
  }

  async loadQrSelectModalContent({ qrId, isChangeQrAction, searchParam }) {
    qrId = Number(qrId);
    const actionButton = isChangeQrAction
      ? this.changeQrLiteBtnTarget
      : this.buyLiteBtnTarget;
    actionButton.classList.remove("d-none");

    const modalBody = this.liteQrSelectModalTarget.querySelector(
      "#qrSelectLiteContent"
    );
    if (this.currentStepModalData === 1) {
      modalBody.innerHTML =
        `<div class="d-flex justify-content-center align-items-center" style="height: 200px;" id="loadingSpinner">
          <div class="spinner-border text-gray" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
        </div>`
      ;
    }

    this.isModalDataLoading = true;
    try {
      const { data } = await axios(
        `/ajax/entry/user-entry-data?revert_result=1&step=${
          this.currentStepModalData
        }${searchParam ? `&entry_title=${searchParam}` : ""}`
      );
      this.modalSearchTarget.classList.remove("d-none");
      if (
        this.currentStepModalData === 1 ||
        this.liteQrSelectModalTarget.dataset.loaded !== '1'
      ) {
        modalBody.innerHTML = "";
      }
      this.currentStepModalData += 1;
      this.totalStepCountModalData = data.pagination['totalStepCount'];

      if (!data.data.length) {
        modalBody.innerHTML = `<div class="text-center my-4 text-gray">${this.notFoundValue}</div>`;
        return;
      }

      data.data.forEach((item) => {
        modalBody.innerHTML +=
          `<div 
            class="qr-item form-check border rounded-1 mb-3 p-2 d-flex align-items-center ${qrId === item.id ? "border-primary" : ""}" 
            data-qr-id='${item.id}'
          >
            <label class="form-check-label ps-3" for="flexRadioDefault${item.id}">
              <div class="row align-items-center">
                <div class="col-1">
                  <input 
                    class="form-check-input m-0 p-0" 
                    type="radio" 
                    name="flexRadioDefault" 
                    id="flexRadioDefault${item.id}" 
                    ${qrId === item.id ? "checked" : ""}
                  >
                </div>
                <div class="col-3">
                  ${
                    item.image 
                      ? 
                        `<div class="d-flex justify-content-center align-items-center h-100"">
                          <img src="${item.image}" class="w-100">
                        </div>`
                      : 
                        `<div 
                          class="qrcode d-flex justify-content-center align-items-center h-100" 
                          data-link="${item.link}"
                        ></div>`
                  }
                </div>
                <div class="col-8">
                  <div class="fw-bold">${item.title}</div>
                  <div class="overflow-hidden">
                    <span class="text-gray me-2">URL:</span>
                    <span>${item.link}</span>
                  </div>
                  <div>
                    <span class="text-gray me-2">${this.typeTextValue}:</span>
                    <span>${item.type}</span>
                  </div>
                </div>
              </div>
            </label>
        </div>`
      ;
      });

      modalBody.querySelectorAll(".qrcode").forEach((item) => {
        item.innerHTML = "";
        let newQr = new this.QRCodeStyling({ data: item.dataset.link });
        newQr.append(item);
      });

      this.liteQrSelectModalTarget.dataset.loaded = '1';
      const qrItems = document.querySelectorAll(".qr-item");
      qrItems.forEach((qrItem) => {
        qrItem.addEventListener("click", (e) => {
          qrItems.forEach((item) => {
            item.classList.remove("border-primary");
            const input = item.querySelector("input");
            input.removeAttribute("checked");
          });
          e.currentTarget.classList.add("border-primary");
          const input = e.currentTarget.querySelector("input");
          input.checked = true;
          input.setAttribute("checked", "");
          this.qrIdLiteModal = qrItem.dataset.qrId;
        });
      });
    } catch (err) {
    } finally {
      this.loadingSpinnerShow(false);
      this.isModalDataLoading = false;
    }
  }

  checkModalScroll() {
    const modalContent = document.querySelector("#qrSelectLiteContent");
    modalContent.addEventListener("scroll", () => {
      if (
        !this.isModalDataLoading &&
        this.currentStepModalData <= this.totalStepCountModalData &&
        modalContent.scrollHeight - modalContent.scrollTop <=
          modalContent.clientHeight + 100
      ) {
        this.loadingSpinnerShow(true);
        this.loadQrSelectModalContent({
          qrId: this.isInitModalAsSubscribeMode ? null : this.qrIdLiteModal,
          isChangeQrAction: !this.isInitModalAsSubscribeMode,
        });
      }
    });
  }

  loadingSpinnerShow(isShow) {
    const modalContent = document.querySelector("#qrSelectLiteContent");
    let newDiv = document.createElement("div");
    newDiv.innerHTML =
      `<div class="text-center" id="loadingSpinner">
        <div class="spinner-border text-gray" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>`
    ;
    if (isShow) {
      modalContent.appendChild(newDiv);
    } else {
      const loadingSpinner = modalContent.querySelector("#loadingSpinner");
      loadingSpinner?.parentNode.removeChild(loadingSpinner);
    }
  }

  async saveLiteQrSelect() {
    try {
      await axios.post(
        `/subscription/change-lite/order/${this.qrOrderIdLiteModal}/qr/${this.qrIdLiteModal}/`
      );
      this.liteQrSelectModal.hide();
      window.location.reload();
    } catch (err) {
      this.showErrorMsg(this.changeQrErrTarget);
    }
  }

  resetLiteQrSelectModal() {
    this.qrOrderIdLiteModal = null;
    this.qrIdLiteModal = null;
    this.changeQrLiteBtnTarget.classList.add("d-none");
    this.buyLiteBtnTarget.classList.add("d-none");
    const qrItems = document.querySelectorAll(".qr-item");
    qrItems.forEach((item) => {
      const inputElement = item.querySelector("input");
      inputElement.removeAttribute("checked");
      inputElement.checked = false;
      item.classList.remove("border-primary");
    });
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }

  switchPlans(e) {
    this.toggleSwitchPlans(e.target.checked);
  }

  toggleSwitchPlans(isToggle) {
    const action = isToggle ? "add" : "remove";
    const reverseAction = isToggle ? "remove" : "add";
    this.monthlyLabelTarget.classList[action](
      "fw-bold",
      "text-gray",
      "text-dark"
    );
    this.annuallyLabelTarget.classList[reverseAction](
      "fw-bold",
      "text-gray",
      "text-dark"
    );
    this.monthlyTargets.forEach((item) => {
      item.classList[reverseAction]("d-none");
    });
    this.annuallyTargets.forEach((item) => {
      item.classList[action]("d-none");
    });
    this.storageMonthYearTarget.innerHTML =
      this.storageMonthYearTarget.dataset[isToggle ? "month" : "year"];
    this.isShowMultiSubValue
      ? this.setStorageMultiSubPrice()
      : this.setStoragePrice();
  }

  resetStorageSlider() {
    const selectedStorageIndex = this.getIndexInRange(
      this.changeSizeBtnModalTarget.dataset.allowedFileSize
    );
    this.storageChoiceTarget.value = selectedStorageIndex;
    this.selectedQuantityUnits = quantity[selectedStorageIndex];
    this.storageMBTarget.innerHTML = sizeOptions[selectedStorageIndex];
    const thumb = this.storageThumbTarget;
    thumb.textContent = sizeOptions[selectedStorageIndex];
    if (this.isShowMultiSubValue) {
      this.selectStoragePeriodTarget.selectedIndex = 0;
      this.setStorageMultiSubPrice();
    } else {
      this.setStoragePrice();
    }
  }

  handleDetails(e) {
    const target = e.currentTarget;
    const contentSpan = target.querySelector("span");
    const imgElement = target.querySelector(".tariff-details-img");

    contentSpan.innerHTML =
      target.innerText.trim() === target.dataset.hideText
        ? target.dataset.showText
        : target.dataset.hideText;

    target.previousElementSibling.classList.toggle("d-none");
    if (!target.previousElementSibling.classList.contains("d-none")) {
      imgElement.classList.add("rotate");
    } else {
      imgElement.classList.remove("rotate");
    }
  }

  changeSlider() {
    const slider = this.storageChoiceTarget;
    const thumb = this.storageThumbTarget;
    const valueIndex = parseInt(slider.value);
    const sizeMemory = sizeOptions[valueIndex];
    thumb.textContent = sizeMemory;
    this.storageMBTarget.textContent = sizeMemory;
    const sliderWidth = slider.offsetWidth;
    const thumbWidth = thumb.offsetWidth;
    const max = slider.max;
    const min = slider.min;
    const range = max - min;

    const percent = ((slider.value - min) / range) * 100;
    const leftOffset = (percent / 100) * sliderWidth - thumbWidth / 2;
    thumb.style.left = leftOffset + "px";
    this.showStorageRangeInfo(leftOffset);

    this.selectedQuantityUnits = quantity[valueIndex];
    this.isShowMultiSubValue
      ? this.setStorageMultiSubPrice()
      : this.setStoragePrice();
  }

  setStoragePrice() {
    const isMonth = this.switchYearMonthTarget.checked;
    const storagePeriod = isMonth ?
        paymentMiddlewareUrlService.storageMonthType :
        paymentMiddlewareUrlService.storageYearType
    ;
    const storagePlan = `${storagePeriod}_${this.selectedQuantityUnits * 100}mb`;
    const price = this.plansData[storagePlan]?.find(
      (item) => item.currency === this.currentCurrency
    ).amount || 0;
    this.storagePriceTarget.innerHTML = `${
      this.symbol[this.currentCurrency]
    }${price}`;
  }

  setStorageMultiSubPrice() {
    const selectedOption = this.selectStoragePeriodTarget.options[this.selectStoragePeriodTarget.selectedIndex];
    const storagePlan = `storage_${selectedOption.dataset.type}_${this.selectedQuantityUnits * 100}mb`;
    const price = this.plansData[storagePlan]?.find(
      (item) => item.currency === this.currentCurrency
    ).amount || 0;

    this.storagePriceTarget.innerHTML = `${this.symbol[this.currentCurrency]}${(
      price * Number(selectedOption.dataset.quantity)
    ).toFixed(2)}`;
  }

  changeStorageMultiSubPeriod() {
    this.setStorageMultiSubPrice();
  }

  extendStorage() {
    if (this.isShowMultiSubValue) {
      const selectedOption = this.selectStoragePeriodTarget.options[this.selectStoragePeriodTarget.selectedIndex];
      let subscriptionType = paymentMiddlewareUrlService.storageMonthType;
      if (selectedOption.dataset.type === "year") {
        subscriptionType = paymentMiddlewareUrlService.storageYearType;
      }

      window.location.href = paymentMiddlewareUrlService.getStorageMiddlewareUrl({
        subscriptionType: subscriptionType,
        currency: this.currentCurrency,
        quantityUnits: this.selectedQuantityUnits,
        quantityOrder: selectedOption.dataset.quantity,
        coupon: this.coupon,
      });
    } else {
      const isMonth = this.switchYearMonthTarget.checked;
      let subscriptionType = paymentMiddlewareUrlService.storageMonthType;
      if (!isMonth) {
        subscriptionType = paymentMiddlewareUrlService.storageYearType;
      }

      window.location.href = paymentMiddlewareUrlService.getStorageMiddlewareUrl({
        subscriptionType: subscriptionType,
        currency: this.currentCurrency,
        quantityUnits: this.selectedQuantityUnits,
        coupon: this.coupon,
      });
    }
  }

  openSubscriptionModal(e) {
    this.cancelSubscriptionTarget.dataset.plan = e.currentTarget.dataset.plan;
    this.cancelSubscriptionTarget.dataset.order = e.currentTarget.dataset.order;
  }

  openSubscriptionStorageModal(e) {
    this.cancelSubscriptionStorageTarget.dataset.plan =
      e.currentTarget.dataset.plan;
    this.cancelSubscriptionStorageTarget.dataset.order =
      e.currentTarget.dataset.order;
    this.storageSubscribeWeeksUntilTarget.innerText =
      this.storageSubscriptionWeeksUntil(
        e.currentTarget.dataset.subscriptionDueDate
      );
  }

  async searchQr(e) {
    this.currentStepModalData = 1;
    this.totalStepCountModalData = 1;
    await this.loadQrSelectModalContent({
      qrId: this.isInitModalAsSubscribeMode ? null : this.qrIdLiteModal,
      isChangeQrAction: !this.isInitModalAsSubscribeMode,
      searchParam: e.target.value,
    });
  }

  initStorageModal() {
    setTimeout(() => {
      const rangeInput = this.storageChoiceTarget;
      rangeInput.dispatchEvent(new Event('input', { bubbles: true }));
    }, 500)
    const percentUserStorage =
      (100 / (quantity.length - 1)) *
        this.getIndexInRange(
          this.changeSizeBtnModalTarget.dataset.summaryFileSize
        ) +
      100 / (quantity.length - 1) / 2;
    const percentsubscribedStorage =
      (100 / (quantity.length - 1)) *
      this.getIndexInRange(
        this.changeSizeBtnModalTarget.dataset.allowedFileSize
      );
    this.userStorageTarget.style.width = `${percentUserStorage}%`;
    this.subscribedStorageTarget.style.width = `${percentsubscribedStorage}%`;
  }

  getIndexInRange(value) {
    if (value < 100) return -1;
    const target = parseInt(value / 100);

    const closestIndex = quantity.reduce((prevIndex, currValue, currIndex) => {
      if (prevIndex === -1) return currIndex;
      const prevDifference = Math.abs(quantity[prevIndex] - target);
      const currDifference = Math.abs(currValue - target);
      return currDifference < prevDifference ? currIndex : prevIndex;
    }, -1);
    return closestIndex;
  }

  showStorageRangeInfo(position) {
    const slider = this.storageChoiceTarget;
    const sliderValue = parseInt(slider.value);
    const summaryFileSize = parseInt(
      this.changeSizeBtnModalTarget.dataset.summaryFileSize
    );
    const allowedFileSize = parseInt(
      this.changeSizeBtnModalTarget.dataset.allowedFileSize
    );

    const summaryIndex = this.getIndexInRange(summaryFileSize);
    const allowedIndex = this.getIndexInRange(allowedFileSize);
    if (sliderValue <= summaryIndex) {
      this.displayTooltip(this.tooltipSummaryFileSizeTarget, position);
      this.configureUserStorage();
      if(summaryIndex > allowedIndex) {
        this.storageChoiceTarget.classList.remove("additional-storage");
        this.storageChoiceTarget.classList.remove("user-storage"); 
        this.subscribedStorageTarget.style.zIndex = 9;
        this.userStorageTarget.style.zIndex = 8;
      }
    } else {
      this.hideTooltip(this.tooltipSummaryFileSizeTarget);
      this.updateStorageSizeDisplay(summaryFileSize);
      if (Number(slider.value) === allowedIndex) {
        this.extendStorageBtnTarget.classList.add("disabled");
      } else {
        this.extendStorageBtnTarget.classList.remove("disabled");
      }
      if (sliderValue <= allowedIndex) {
        if (sliderValue < allowedIndex) {
          this.displayTooltip(this.tooltipAllowedFileSizeTarget, position);
        } else {
          this.hideTooltip(this.tooltipAllowedFileSizeTarget);
        }
        this.configureSubscribedStorage();
      } else {
        this.hideTooltip(this.tooltipAllowedFileSizeTarget);
        this.hideTooltip(this.tooltipSummaryFileSizeTarget);
        this.configureAdditionalStorage();
        if(summaryIndex > allowedIndex) {
          this.subscribedStorageTarget.style.zIndex = 13;
        }
      }
    }
  }

  displayTooltip(tooltipElement, position) {
    tooltipElement.classList.remove("d-none");
    tooltipElement.style.left = `${position}px`;
    const storageModal = document.querySelector("#storageModalBody");
    const tooltipBlockRect = tooltipElement.getBoundingClientRect();
    const storageModalBlockRect = storageModal.getBoundingClientRect();
    if (tooltipBlockRect.left < storageModalBlockRect.left) {
      tooltipElement.style.left = "0px";
      tooltipElement.style.right = "auto";
    } else if (tooltipBlockRect.right > storageModalBlockRect.right) {
      tooltipElement.style.right = "0px";
      tooltipElement.style.left = "auto";
    }
  }

  hideTooltip(tooltipElement) {
    tooltipElement.classList.add("d-none");
  }

  updateStorageSizeDisplay(fileSize) {
    const sizeInGb = (fileSize / 1000).toFixed(3);
    this.tooltipSummaryFileSizeTarget.querySelector(
      "#storageSummarySizeInGb"
    ).innerText = sizeInGb;
  }

  configureUserStorage() {
    this.tooltipSummaryFileSizeTarget.classList.remove("d-none");
    this.userStorageTarget.style.zIndex = 9;
    this.storageChoiceTarget.classList.remove("user-storage");
    this.extendStorageBtnTarget.classList.add("disabled");
    this.tooltipAllowedFileSizeTarget.classList.add("d-none");
  }

  configureSubscribedStorage() {
    this.storageChoiceTarget.classList.add("user-storage");
    this.subscribedStorageTarget.style.zIndex = 8;
    this.userStorageTarget.style.zIndex = 11;
    this.storageChoiceTarget.classList.remove("additional-storage");
  }

  configureAdditionalStorage() {
    this.subscribedStorageTarget.style.zIndex = 11;
    this.userStorageTarget.style.zIndex = 12;
    const isLargeFileSize =
      parseInt(this.changeSizeBtnModalTarget.dataset.allowedFileSize / 100) >
      quantity[0];
    this.storageChoiceTarget.classList.add(
      isLargeFileSize ? "additional-storage" : "user-storage"
    );
  }

  storageSubscriptionWeeksUntil(targetDateStr) {
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const msDifference = targetDate - currentDate;
    const msPerWeek = 7 * 24 * 60 * 60 * 1000;
    const weeksRemaining = Math.ceil(msDifference / msPerWeek);
    return weeksRemaining > 0 ? weeksRemaining : 0;
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [key, value] = el.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }

  sendMessageToMobileApp(message) {
    if (!webView && !webviewEvents) {
      return;
    }
    const reactNativeWebView = window['ReactNativeWebView'] ?? null;
    if (!reactNativeWebView) {
      return;
    }

    reactNativeWebView.postMessage("app_subscription_lite");
  }
}