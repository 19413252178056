import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["videoThumb", "parentVideoThumb"];

  showVideo() {
    const embedValue = this.videoThumbTarget.getAttribute("data-embed-value")
    const embedUrl = `${embedValue}?autoplay=1`;
    this.videoThumbTarget.remove();
    this.parentVideoThumbTarget.innerHTML = `<iframe width="100%" height="100%" src="${embedUrl}" allow="autoplay" allowfullscreen></iframe>`;
  }
}